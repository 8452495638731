@use '~scss/settings' as *;

.container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
}

.section-container {
  width: 600px;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.form-container {
  @media screen and (max-width: 720px) {
    width: 100%;
  }
}
