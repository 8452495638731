@use '~scss/settings' as *;

.lgu-file-modal {
  &__form {
    &__file-input {
      position: relative;
      &::after {
        content: '*';
        color: rgb(220, 0, 0);
        position: absolute;
        margin-top: -10px;
      }
    }
  }
}
