@use '~scss/settings' as *;

.lgu-feedback-form {

  button.ui.button {
    margin-top: 10px;
  }

  h4.ui.header {
    margin-top: 10px;
  }

  &__rating {
    @include flex(row, space-evenly);
    margin-top: 10px;
   
    button {
      background-color: transparent;
      border: none;
    }

    &__button {
      @include flex(column);
      gap: 7px;
      margin-top: 10px;
      &--positive {
        color: green;
      }
      &--negative {
        color: red;
      }
      &--has-pointer {
        cursor: pointer;
      }
    }
  }
}
