@use '~scss/settings' as *;

html,
body,
#root {
  @include size(100%);
}

body {
  @extend %font;

  margin: 0;
  overscroll-behavior: none;
}

.table-list {
  &.ui.list {
    > .item[role='listitem'] {
      &:nth-child(2n + 1) {
        background: rgba(gray, 0.1);
      }

      display: flex;
      padding: 12px 10px;

      .ui.list {
        [role='listitem'] {
          &:before {
            content: '\2714';
          }
        }
      }

      pre {
        white-space: break-spaces;
      }

      > * {
        flex: 1 1 50%;
      }

      &:after {
        content: none;
      }
    }
  }
}

.analytics-card{
  text-align: center;

  header {
      background-color: #0000007d!important;
      color: white;
  }
}