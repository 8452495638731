@use '~scss/settings' as *;

  
.landing-container {
    height: 80vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
  }


.section-container {
    margin: auto!important;
    width: 600px;

    @media screen and (max-width: 720px) {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.form-container {
    @media screen and (max-width: 720px) {
        width: 100%;
    }
}