@use '~scss/settings' as *;

.lgu-chat {
  @include flex(column);
  width: 100%;
  background-color: #ffffff;

  &__section,
  .ui.segment.lgu-chat__section--active {
    flex: 1;
    width: 600px;

    &--answer {
      margin-bottom: 15px;
    }

    @media screen and (max-width: 720px) {
      @include size(100%);
      @include flex(column);
    }
  }

  .ui.segment.lgu-chat__section--active {
    animation: sectionAnimation 2s ease-in-out;
  }

  @keyframes sectionAnimation {
    0% {
      background-color: rgba(79, 174, 221, 0);
    }
    50% {
      background-color: rgba(79, 174, 221, 0.1);
    }
    100% {
      background-color: rgba(79, 174, 221, 0);
    }
  }
}
