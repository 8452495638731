@use '~scss/settings' as *;

.lgu-home-page {
  &__sidebar {
    @include size(85px, 100%);
    position: fixed;
    z-index: 1;
    top: 0;
    background-color: #1b1c1d;
    transition: 0.5s;
    overflow-x: hidden;
    padding-top: 50px;
    white-space: nowrap;

    &:hover {
      width: 260px;
    }

    a {
      padding: 8px 8px 8px 25px;
      text-decoration: none;
      font-size: 20px;
      color: #c2c2c2;
      display: block;
      margin-top: 10px;

      i {
        margin-right: 30px;
      }

      &:hover {
        color: #f1f1f1;
      }
    }
  }

  &__nav {
    @include size(100%, 60px);
    @include flex(row, flex-end);
    position: fixed;
    top: 0;
    z-index: 1;
    background-color: #1b1c1d;
    gap: 10px;

    a {
      text-decoration: none;
      font-size: 20px;
      color: #c2c2c2;
      margin-right: 15px;

      &:hover {
        color: #f1f1f1;
      }
    }
  }

  &__routes,
  &__routes--hover {
    margin: 80px 0 0 140px;
    transition: 0.5s;
    width: calc(100% - 185px);
  }
  &__routes--hover {
    transition: 0.5s;
    opacity: 0.4;
  }
}
