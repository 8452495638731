@forward 'color';
@forward 'variables';
@forward 'breakpoints';
@forward 'tools/tools';
@forward 'resets';

%font {
  font-family: Raleway, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

@mixin shadow-standard() {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
}

@mixin flex($direction: row, $justify-content: center, $align-items: center) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify-content;
  align-items: $align-items;
}

@mixin flex-start($direction: row) {
  @include flex($direction, flex-start, flex-start);
}

@mixin flex-row($justify-content: flex-start, $align-items: flex-start) {
  @include flex(row, $justify-content, $align-items);
}

@mixin flex-column($justify-content: flex-start, $align-items: flex-start) {
  @include flex(column, $justify-content, $align-items);
}

.text-center{
  text-align: center;
}

.ui.horizontal.list.centered {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  & .item {
      text-align: center;
  }
}

.empty-table-row{
  height: 500px;
  text-align: center;
}